@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?7741637');
  src: url('../font/fontello.eot?7741637#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?7741637') format('woff2'),
       url('../font/fontello.woff?7741637') format('woff'),
       url('../font/fontello.ttf?7741637') format('truetype'),
       url('../font/fontello.svg?7741637#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?7741637#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-earth:before { content: '\e800'; } /* '' */
.icon-payment:before { content: '\e801'; } /* '' */
.icon-phone:before { content: '\e802'; } /* '' */
.icon-office:before { content: '\e803'; } /* '' */
.icon-secure:before { content: '\e804'; } /* '' */
.icon-upload:before { content: '\e805'; } /* '' */
.icon-account:before { content: '\e806'; } /* '' */
.icon-bell:before { content: '\e807'; } /* '' */
.icon-bill:before { content: '\e808'; } /* '' */
.icon-building:before { content: '\e809'; } /* '' */
.icon-close:before { content: '\e80a'; } /* '' */
.icon-coupon:before { content: '\e80b'; } /* '' */
.icon-dashboard:before { content: '\e80c'; } /* '' */
.icon-event-b:before { content: '\e80d'; } /* '' */
.icon-group-b:before { content: '\e80e'; } /* '' */
.icon-help:before { content: '\e80f'; } /* '' */
.icon-member:before { content: '\e810'; } /* '' */
.icon-menu:before { content: '\e811'; } /* '' */
.icon-member-o:before { content: '\e812'; } /* '' */
.icon-check-bold:before { content: '\e813'; } /* '' */
.icon-check:before { content: '\e814'; } /* '' */
.icon-add-2:before { content: '\e815'; } /* '' */
.icon-up:before { content: '\e816'; } /* '' */
.icon-bill-o:before { content: '\e817'; } /* '' */
.icon-coupon-o:before { content: '\e818'; } /* '' */
.icon-download:before { content: '\e819'; } /* '' */
.icon-edit:before { content: '\e81a'; } /* '' */
.icon-event-o:before { content: '\e81b'; } /* '' */
.icon-eye:before { content: '\e81c'; } /* '' */
.icon-exit:before { content: '\e81d'; } /* '' */
.icon-group-o:before { content: '\e81e'; } /* '' */
.icon-help-o:before { content: '\e81f'; } /* '' */
.icon-info-o:before { content: '\e820'; } /* '' */
.icon-left-big-arrow:before { content: '\e821'; } /* '' */
.icon-right-big-arrow:before { content: '\e822'; } /* '' */
.icon-cloud-upload:before { content: '\e823'; } /* '' */
.icon-calendar-o:before { content: '\e824'; } /* '' */
.icon-edit-o:before { content: '\e825'; } /* '' */
.icon-magnifier:before { content: '\e826'; } /* '' */
.icon-circle-plus:before { content: '\e827'; } /* '' */
.icon-ticket:before { content: '\e828'; } /* '' */
.icon-trash:before { content: '\e829'; } /* '' */
.icon-user-checked:before { content: '\e82a'; } /* '' */
.icon-user-o:before { content: '\e82b'; } /* '' */
.icon-group-checked:before { content: '\e82c'; } /* '' */
.icon-coupon-checked:before { content: '\e82d'; } /* '' */
.icon-cart:before { content: '\e82e'; } /* '' */
.icon-travel-card:before { content: '\e82f'; } /* '' */
.icon-card-swipe:before { content: '\e830'; } /* '' */
.icon-credit-card:before { content: '\e831'; } /* '' */
.icon-loud-horm:before { content: '\e832'; } /* '' */
.icon-paper-plane:before { content: '\e833'; } /* '' */
.icon-mail:before { content: '\e834'; } /* '' */
.icon-pin:before { content: '\e835'; } /* '' */
.icon-clock:before { content: '\e836'; } /* '' */
.icon-arrows-cw:before { content: '\e837'; } /* '' */
.icon-down:before { content: '\e838'; } /* '' */
.icon-up-small:before { content: '\e839'; } /* '' */
.icon-down-small:before { content: '\e83a'; } /* '' */
.icon-left:before { content: '\e83b'; } /* '' */
.icon-right:before { content: '\e83c'; } /* '' */
.icon-resize-vertical:before { content: '\e83d'; } /* '' */
.icon-resize-horizontal:before { content: '\e83e'; } /* '' */
.icon-right-arrow:before { content: '\e83f'; } /* '' */
.icon-password:before { content: '\e840'; } /* '' */
.icon-down-open:before { content: '\f004'; } /* '' */
.icon-up-open:before { content: '\f005'; } /* '' */
.icon-right-open:before { content: '\f006'; } /* '' */
.icon-left-open:before { content: '\f007'; } /* '' */
.icon-list-bullet:before { content: '\f0ca'; } /* '' */
.icon-paypal:before { content: '\f342'; } /* '' */